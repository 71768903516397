const _getCenteredPosition = (target, parent, selfReference, targetReference) =>
    target - parent - (selfReference - targetReference) / 2;

const _getRelativePosition = (target, parent, relativeReference) =>
    target - parent + relativeReference;

const _getBottomPosition = ({
    targetBoundingRect,
    parentBoundingRect,
    selfWidth,
    selfHeight,
}) => ({
    left: _getCenteredPosition(
        targetBoundingRect.left,
        parentBoundingRect.left,
        selfWidth,
        targetBoundingRect.width,
    ),
    top: _getRelativePosition(
        targetBoundingRect.top,
        parentBoundingRect.top,
        -selfHeight,
    ),
});

const _getTopPosition = ({
    targetBoundingRect,
    parentBoundingRect,
    selfWidth,
}) => ({
    left: _getCenteredPosition(
        targetBoundingRect.left,
        parentBoundingRect.left,
        selfWidth,
        targetBoundingRect.width,
    ),
    top: _getRelativePosition(
        targetBoundingRect.top,
        parentBoundingRect.top,
        targetBoundingRect.height,
    ),
});

const _getLeftPosition = ({
    targetBoundingRect,
    parentBoundingRect,
    selfHeight,
}) => ({
    left: _getRelativePosition(
        targetBoundingRect.left,
        parentBoundingRect.left,
        targetBoundingRect.width,
    ),
    top: _getCenteredPosition(
        targetBoundingRect.top,
        parentBoundingRect.top,
        selfHeight,
        targetBoundingRect.height,
    ),
});

const _getRightPosition = ({
    targetBoundingRect,
    parentBoundingRect,
    selfWidth,
    selfHeight,
}) => ({
    left: _getRelativePosition(
        targetBoundingRect.left,
        parentBoundingRect.left,
        -selfWidth,
    ),
    top: _getCenteredPosition(
        targetBoundingRect.top,
        parentBoundingRect.top,
        selfHeight,
        targetBoundingRect.height,
    ),
});

const RELATIVE_LOCATION_MAP = {
    bottom: (dimensions) => _getBottomPosition(dimensions),
    top: (dimensions) => _getTopPosition(dimensions),
    left: (dimensions) => _getLeftPosition(dimensions),
    right: (dimensions) => _getRightPosition(dimensions),
};

/*
    Util which given an HTML targetNode, the components own height/width, and a relative
    location returns the absolutely positioned style of the component such that the targetNode
    is positioned in the `relativeLocation` to the component.

    ie. relativeLocation = 'bottom' means targetNode is *uderneath* the component
    and the component is positioned directly *above* the targetNode.
*/
export const getLocation = (
    targetNode,
    relativeLocation,
    selfWidth,
    selfHeight,
) => {
    const targetBoundingRect = targetNode.getBoundingClientRect();
    const parentBoundingRect = targetNode.offsetParent
        ? targetNode.offsetParent.getBoundingClientRect()
        : targetNode.getBoundingClientRect();

    return RELATIVE_LOCATION_MAP[relativeLocation]({
        targetBoundingRect,
        parentBoundingRect,
        selfWidth,
        selfHeight,
    });
};
