import * as React from 'react';

const HelpChunkySvg = (props: React.SVGProps<SVGSVGElement>) => (
    <svg
        id="help-chunky_svg__eds-icon--info-chunky_svg"
        x={0}
        y={0}
        viewBox="0 0 24 24"
        xmlSpace="preserve"
        {...props}
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12 6c3.3 0 6 2.7 6 6s-2.7 6-6 6-6-2.7-6-6 2.7-6 6-6zm0 14c4.4 0 8-3.6 8-8s-3.6-8-8-8-8 3.6-8 8 3.6 8 8 8z"
        />
        <path d="M12.848 13.9a74.6 74.6 0 001.298-2.205c.272-.49.457-.877.556-1.161a2.53 2.53 0 00.148-.834c0-.454-.1-.845-.303-1.174-.202-.33-.502-.583-.902-.76-.4-.177-.887-.266-1.464-.266-.964 0-1.66.22-2.088.661-.429.44-.643 1.077-.643 1.91h1.384c0-.314.033-.573.099-.78a.88.88 0 01.383-.487c.19-.12.457-.18.803-.18.445 0 .762.1.952.297.19.198.284.474.284.828 0 .222-.05.467-.148.735-.1.268-.29.653-.575 1.156a94.695 94.695 0 01-1.34 2.26h1.556zm-.815 2.72a.92.92 0 00.66-.254.857.857 0 00.266-.649.892.892 0 00-.265-.667.909.909 0 00-.661-.26c-.28 0-.505.087-.674.26a.915.915 0 00-.253.667c0 .264.084.48.253.65.169.168.394.252.674.252z" />
    </svg>
);

HelpChunkySvg.displayName = 'HelpChunkySvg';
export default HelpChunkySvg;
