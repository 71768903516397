import * as React from 'react';

const ShareIosChunkySvg = (props: React.SVGProps<SVGSVGElement>) => (
    <svg
        id="share-ios-chunky_svg__eds-icon--share-ios-chunky_svg"
        x={0}
        y={0}
        viewBox="0 0 24 24"
        xmlSpace="preserve"
        {...props}
    >
        <path
            id="share-ios-chunky_svg__eds-icon--share-ios-chunky_base"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M18 16v2H6v-2H4v4h16v-4z"
        />
        <path
            id="share-ios-chunky_svg__eds-icon--share-ios-chunky_arrow"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12 4L7 9l1.4 1.4L11 7.8V16h2V7.8l2.6 2.6L17 9l-5-5z"
        />
    </svg>
);

ShareIosChunkySvg.displayName = 'ShareIosChunkySvg';
export default ShareIosChunkySvg;
