import PropTypes from 'prop-types';
import { translationPropType } from '@eventbrite/i18n';
import { makeMaxCharCountPropType, makePropType } from '@eventbrite/eds-utils';

const MAX_CHAR_COUNT = 32;

export const VALID_DIRECTIONS = ['left', 'right', 'top', 'bottom'];

export const TOOLTIP_CONTENT_PROPTYPE = PropTypes.oneOfType([
    makePropType(makeMaxCharCountPropType(MAX_CHAR_COUNT)),
    PropTypes.element,
    translationPropType,
]);
export const ARROW_LOCATION_PROPTYPE = PropTypes.oneOf(VALID_DIRECTIONS);
export const STYLE_PROPTYPE = PropTypes.oneOf(['basic', 'phab']);

export const STANDARD_ARROW_SPACING = 12;

export const ANIMATION_MAP = {
    left: 'eds-fx--fade-in-left',
    right: 'eds-fx--fade-in-right',
    top: 'eds-fx--fade-in-down',
    bottom: 'eds-fx--fade-in-up',
};
