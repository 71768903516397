import { logEvent } from '@eventbrite/statsig';
import { HEAP_EVENT_CLICK } from '../../constants/analytics';

import {
    EVENTBRITE_CATEGORY_TO_REFINEMENT,
    isCategory,
} from '@eventbrite/categories';
import { hasCarouselWidget } from '../../transforms';
import { FormattedEvent } from '../../types';
import { isPromoCodeActive } from '../EventCardPromoCode';
import { areUrgencySignalsActive } from '../EventCardUrgencySignals';

type EventTrackingMetaData = Pick<
    FormattedEvent,
    | 'isPromoted'
    | 'isFree'
    | 'isOnlineEvent'
    | 'urgencySignals'
    | 'widgets'
    | 'specialDiscounts'
    | 'openDiscount'
>;

/**
 * Formats statsig metadata to a string to match
 * expected API formatting.
 *
 * @param event
 * @returns
 */
function formatEventMetaData(
    event: EventTrackingMetaData,
    statsigMetadata: Record<string, string> = {},
) {
    const eventMetaData = {
        isPromoted: `${Boolean(event.isPromoted)}`,
        isFree: `${Boolean(event.isFree)}`,
        isOnline: `${Boolean(event.isOnlineEvent)}`,
        hasUrgencySignals: `${Boolean(areUrgencySignalsActive(event))}`,
        hasCarousel: `${hasCarouselWidget(event)}`,
        hasPromoCode: `${isPromoCodeActive(event.openDiscount)}`,
        hasBogoLabel: `${!!event.specialDiscounts?.hasBogoTickets}`,
        ...statsigMetadata,
    };
    return eventMetaData;
}

export function logStatsigClick(
    statsigLocationString: string,
    event: EventTrackingMetaData,
    statsigMetadata?: Record<string, string>,
) {
    logEvent(
        `${statsigLocationString} - ${HEAP_EVENT_CLICK}`,
        undefined,
        formatEventMetaData(event, statsigMetadata),
    );
}

export function formatVenueToCityRegion({
    venue,
    isOnlineEvent,
}: Pick<FormattedEvent, 'isOnlineEvent' | 'venue'>) {
    if (isOnlineEvent) return 'online';
    if (!venue) return null;
    return [venue.address.city, venue.address.region].join(', ');
}

export function formatPaidStatus(
    isFree: Pick<FormattedEvent, 'isFree'>['isFree'],
) {
    return isFree ? 'free' : 'paid';
}

/**
 * Filters a list of tags and returns the first category
 */
export function formatCategoryFromTags(
    tags?: Pick<FormattedEvent, 'tags'>['tags'],
) {
    if (!tags) return null;
    const category = tags.find((tag) => isCategory(tag));
    if (!category) return null;
    return EVENTBRITE_CATEGORY_TO_REFINEMENT[category];
}
