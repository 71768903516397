import * as React from 'react';

const HeartFillChunkySvg = (props: React.SVGProps<SVGSVGElement>) => (
    <svg
        id="heart-fill-chunky_svg__eds-icon--heart-fill-chunky_svg"
        x={0}
        y={0}
        viewBox="0 0 24 24"
        xmlSpace="preserve"
        {...props}
    >
        <path
            id="heart-fill-chunky_svg__eds-icon--heart-fill-chunky_base"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M16 5c-1 0-2 .4-2.8 1.2L12 7.4l-1.2-1.2C10 5.4 9 5 8 5c-1 0-2 .4-2.8 1.2-1.5 1.6-1.5 4.2 0 5.8l6.8 7 6.8-7c1.5-1.6 1.5-4.2 0-5.8C18.1 5.4 17 5 16 5"
        />
    </svg>
);

HeartFillChunkySvg.displayName = 'HeartFillChunkySvg';
export default HeartFillChunkySvg;
